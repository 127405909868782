import { Text, Grid } from '@constellation/core';
import { Tick } from '@constellation/core/icons';
import styled from 'styled-components';

import { SwitchStyleProps } from './switch.config';

export const StyledHiddenInput = styled('input')`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  ~ span span:first-of-type {
    width: fit-content !important;
  }
`;

const StyledSwitchButton = styled('span')`
  display: flex;
  border: 1px solid ${({ theme }) => theme.color_input_border_default};
  pointer: cursor;
  pointer-events: none;
  background: ${({ $isChecked }: SwitchStyleProps) => {
    if ($isChecked) {
      return ({ theme }) => theme.color_background_panel_brand_1;
    }
    return 'inherit';
  }};
  padding: ${({ $isChecked }: SwitchStyleProps) => {
    if ($isChecked) {
      return ({ theme }) => `${theme.spacing_03} ${theme.spacing_01}`;
    }
    return ({ theme }) => `${theme.spacing_03} ${theme.spacing_04}`;
  }}};
`;

export const StyledFirstSwitchButton = styled(StyledSwitchButton)`
  border-bottom-left-radius: ${({ theme }) => theme.spacing_03};
  border-top-left-radius: ${({ theme }) => theme.spacing_03};
  border-right: 0;
`;

export const StyledSecondSwitchButton = styled(StyledSwitchButton)`
  border-bottom-right-radius: ${({ theme }) => theme.spacing_03};
  border-top-right-radius: ${({ theme }) => theme.spacing_03};
`;

export const StyledRadioText = styled(Text)`
  color: ${({ $isChecked }: SwitchStyleProps) => {
    if ($isChecked) {
      return ({ theme }) => theme.color_text_inverse_default_1;
    }
    return ({ theme }) => theme.color_text_brand_1;
  }} !important;
`;

export const StyledSwitchContainer = styled(Grid)`
  padding-right: ${({ theme }) => theme.spacing_03};
`;

export const StyledTick = styled(Tick)`
  display: ${({ $isChecked }: SwitchStyleProps) => {
    if ($isChecked) {
      return 'flex';
    }
    return 'none';
  }};
  width: ${({ theme }) => theme.spacing_05} !important;
`;

export const StyledOuterSwitch = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export const StyledOuterButton = styled('div')`
  cursor: pointer;
`;
