import React, { ReactElement, useState } from 'react';

import { Text, Grid, GridItem } from '@constellation/core';

import {
  StyledFirstSwitchButton,
  StyledSecondSwitchButton,
  StyledTick,
  StyledRadioText,
  StyledSwitchContainer,
  StyledHiddenInput,
  StyledOuterSwitch,
  StyledOuterButton,
} from './styledSwitch';
import { ICustomSwitchProps } from './switch.config';
import { isEnterKey } from '../../utils/isEnterKey';

export default function CustomSwitch({
  initialValue,
  handleChange,
  label,
  yesLabel,
  noLabel,
  yesTestId,
  noTestId,
  dataQaId,
}: ICustomSwitchProps): ReactElement {
  const [value, setValue] = useState(initialValue);
  const selectSwitch = (e: any): void => {
    const switchTarget = e.target;
    if (switchTarget.firstChild) {
      switchTarget.firstChild.checked = true;
      handleChange(e.target.children[0].value);
      setValue(e.target.children[0].value);
    }
  };
  return (
    <StyledOuterSwitch role="radiogroup" aria-labelledby={`${label}-label`}>
      <Grid alignY="center">
        <GridItem xs={6} sm={7} md={6}>
          <legend id={`${label}-label`}>
            <Text size="s2">{label}</Text>
          </legend>
        </GridItem>
        <GridItem xs={6} sm={5} md={6}>
          <StyledSwitchContainer alignX="right">
            <StyledOuterButton
              onClick={(e) => selectSwitch(e)}
              onKeyDown={(e) => {
                if (isEnterKey(e.key)) {
                  selectSwitch(e);
                }
              }}
              role="button"
              tabIndex={0}
              data-testid={yesTestId}
              data-qa-id={dataQaId.yesBtn}
            >
              <StyledHiddenInput
                name="switch"
                type="radio"
                value="yes"
                data-testid="yesRadio"
                aria-labelledby={`${label}-true`}
                aria-checked={value === 'yes'}
              />
              <StyledFirstSwitchButton
                className="csl-selector-item__button"
                id={`${label}-true`}
                $isChecked={value === 'yes'}
              >
                <StyledTick
                  size="s2"
                  $isChecked={value === 'yes'}
                  role="img"
                  aria-label="tick"
                />
                <StyledRadioText
                  $isChecked={value === 'yes'}
                  size="s2"
                  weight="bold"
                >
                  {yesLabel}
                </StyledRadioText>
              </StyledFirstSwitchButton>
            </StyledOuterButton>
            <StyledOuterButton
              onClick={(e) => selectSwitch(e)}
              onKeyDown={(e) => {
                if (isEnterKey(e.key)) {
                  selectSwitch(e);
                }
              }}
              role="button"
              tabIndex={0}
              data-testid="noRadioButton"
              data-qa-id={dataQaId.noBtn}
            >
              <StyledHiddenInput
                name="switch"
                type="radio"
                value="no"
                aria-labelledby={`${label}-false`}
                aria-checked={value === 'no'}
                data-testid={noTestId}
              />
              <StyledSecondSwitchButton
                className="csl-selector-item__button"
                id={`${label}-false`}
                $isChecked={value === 'no'}
              >
                <StyledTick
                  size="s2"
                  $isChecked={value === 'no'}
                  role="img"
                  aria-label="tick"
                />
                <StyledRadioText
                  $isChecked={value === 'no'}
                  size="s2"
                  weight="bold"
                >
                  {noLabel}
                </StyledRadioText>
              </StyledSecondSwitchButton>
            </StyledOuterButton>
          </StyledSwitchContainer>
        </GridItem>
      </Grid>
    </StyledOuterSwitch>
  );
}
